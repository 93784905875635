<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="12">
        <h1 class="mt-10  ">Nuevos Anexos</h1>
        <hr class="">
      </v-col>
      <v-col cols="12">
        <v-tabs
            background-color="primary"
            dark
        >
          <v-tab href="#formSpec">
            Anexos Especificaciones Técnicas
          </v-tab>
          <v-tab-item value="formSpec">
            <v-form ref="formSpec" class="mt-10" @submit.prevent="submitFormSpec">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Título</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Título</h1>
                        <trumbowyg
                            v-model="data.titulo"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Título"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Requerimiento</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Requerimiento
                        </h1>
                        <trumbowyg
                            v-model="data.requerimiento"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Requerimiento"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Especificaciones Técnica</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Especificaciones Técnica</h1>
                        <trumbowyg
                            v-model="data.especificaciones_tecnicas"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Especificaciones Técnica"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Proveedor Entrega</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Proveedor Entrega
                        </h1>
                        <trumbowyg
                            v-model="data.proveedor_entrega"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Proveedor Entrega"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Condiciones</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Condiciones</h1>
                        <trumbowyg
                            v-model="data.condiciones"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Condiciones"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Garantías</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Garantías
                        </h1>
                        <trumbowyg
                            v-model="data.garantias"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Garantías"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Anticipo</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Anticipo</h1>
                        <trumbowyg
                            v-model="data.anticipo"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Anticipo"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Forma de Pago</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Forma de Pago
                        </h1>
                        <trumbowyg
                            v-model="data.forma_pago"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Forma de Pago"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-col cols="12" class="mt-4">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab href="#form">
            Descargar Anexos 4.1
          </v-tab>
          <v-tab-item value="form">
            <v-col cols="12" class="mt-4">
              <v-btn type="button"  @click="generateAnex" color="primary" style="width: 200px">
                Generar archivo
              </v-btn>
            </v-col>
            <v-row class="mt-5">
              <v-col cols="3">
                <v-btn type="button" v-if="documentGenerateStatusAnex" @click="downloadFileServerAnex" color="primary"
                       style="width: 200px">
                  Descargar archivo
                </v-btn>
              </v-col>
              <v-col cols="12" v-if="documentGenerateStatusAnex">
                <h1 class="mt-4 mx-2 ">Visualización de documento
                </h1>
                <trumbowyg
                    v-model="documentGenerateAnex"
                    disabled="disabled"
                    name="body"
                    :config="configs.advanced"
                    placeholder="Visualización de documento"
                    class="form-control"
                ></trumbowyg>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab href="#formFianza">
            Descargar Anexos Fianza 4.2
          </v-tab>
          <v-tab-item value="formFianza">
            <v-col cols="12" class="mt-4">
              <v-btn type="button"  @click="generateFinance" color="primary" style="width: 200px">
                Generar archivo
              </v-btn>
            </v-col>
            <v-row class="mt-5">
              <v-col cols="3">
                <v-btn type="button" v-if="documentGenerateStatusFinance" @click="downloadFileServerFinance" color="primary"
                       style="width: 200px">
                  Descargar archivo
                </v-btn>
              </v-col>
              <v-col cols="12" v-if="documentGenerateStatusFinance">
                <h1 class="mt-4 mx-2 ">Visualización de documento
                </h1>
                <trumbowyg
                    v-model="documentGenerateFinance"
                    disabled="disabled"
                    name="body"
                    :config="configs.advanced"
                    placeholder="Visualización de documento"
                    class="form-control"
                ></trumbowyg>
              </v-col>
            </v-row>
          </v-tab-item>

        </v-tabs>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'
export default {
  name: "Tenders",
  data() {
    return {
      overlay: false,
      documentGenerateStatusAnex:false,
      documentGenerateAnex:'',
      documentGenerateFinance:false,
      documentGenerateStatusFinance:false,
      idAnex:20,
      idFinance:21,
      configs: {
        advanced: {
          autogrow: true,
          // Adding color plugin button
          btnsAdd: ['foreColor', 'backColor'],
          btnsDef: {
            // Create a new dropdown
            'btnGrp-image': {
              dropdown: ['base64'],
              ico: 'insertImage'
            }
          },
          // Limit toolbar buttons
          btns: [
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['undo', 'redo'],
            ['superscript', 'subscript'],
            ['btnGrp-image'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['link'],
            ['btnGrp-justify'],
            ['btnGrp-lists'],
            ['horizontalRule'],
            ['foreColor'], ['backColor'],
            ['removeformat'],
            ['fullscreen'],
            [['bold', 'italic'], ['link']]
          ]
        },
      },
      data: {
        tenderId: null,
        formatId: null,
        request: "",
        titulo: '',
        requerimiento: '',
        especificaciones_tecnicas: '',
        proveedor_entrega: '',
        condiciones: '',
        garantias: '',
        anticipo: '',
        forma_pago: ''
      },
    };
  },
  components: {
    Overlay,
    Trumbowyg
  },
  created() {
    this.data.tenderId = this.$route.params.id;
    this.data.formatId = this.$route.params.formatId;

  },
  methods: {
    ...mapActions('annexes', ['createAnnexe','generateHtmlFormatAnx','downloadFileAnx']),
    ...mapActions('anx_performnace_bond', ['createAnxPerformance','generateHtmlFormatAnxPB','downloadFileAnxPB']),
    ...mapActions('anx_spec_tecnic', ['createAnxSpec']),
    async downloadFileServerAnex() {
      this.overlay = true;
      await this.downloadFileAnx({id: this.idAnex, externalId: parseInt(this.data.tenderId)});
      this.overlay = false;
    },
    async downloadFileServerFinance() {
      this.overlay = true;
      await this.downloadFileAnx({id: this.idFinance, externalId: parseInt(this.data.tenderId)});
      this.overlay = false;
    },
    async generateFinance(){
      this.overlay = true
      const response = await this.generateHtmlFormatAnx({id:this.idFinance, tenderId: parseInt(this.data.tenderId)});
      if (!response.error) {
        this.documentGenerateFinance = response;
        this.documentGenerateStatusFinance = true;
      } else {
        this.documentGenerateFinance = response;
        this.documentGenerateStatusFinance = false;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async generateAnex(){
      this.overlay = true
      const response = await this.generateHtmlFormatAnx({id:this.idAnex, tenderId: parseInt(this.data.tenderId)});
      if (!response.error) {
        this.documentGenerateAnex = response;
        this.documentGenerateStatusAnex = true;
      } else {
        this.documentGenerateAnex = response;
        this.documentGenerateStatusAnex = false;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async validateRequired(){
      if (!this.data.clave_licitacion){
        this.sweetAlertWarning('Campo Clave de Licitación es requerido');
        return false
      }
      if (!this.data.nombre_licitacion){
        this.sweetAlertWarning('Campo Nombre de Licitación es requerido');
        return false
      }
      return true;
    },
    async validateRequiredFianza(){
      if (!this.data.clave_licitacion){
        this.sweetAlertWarning('Campo Clave de Licitación es requerido');
        return false
      }
      if (!this.data.nombre_licitacion){
        this.sweetAlertWarning('Campo Nombre de Licitación es requerido');
        return false
      }
      return true;
    },
    async validateRequiredSpec(){
      if (!this.data.titulo){
        this.sweetAlertWarning('Campo Título es requerido');
        return false
      }
      if (!this.data.requerimiento){
        this.sweetAlertWarning('Campo Requerimiento es requerido');
        return false
      }
      if (!this.data.especificaciones_tecnicas){
        this.sweetAlertWarning('Campo Especificaciones Técnicas es requerido');
        return false
      }
      if (!this.data.proveedor_entrega){
        this.sweetAlertWarning('Campo Proveedor de Entrega es requerido');
        return false
      }
      if (!this.data.condiciones){
        this.sweetAlertWarning('Campo Condiciones es requerido');
        return false
      }
      if (!this.data.garantias){
        this.sweetAlertWarning('Campo Garantías es requerido');
        return false
      }
      if (!this.data.anticipo){
        this.sweetAlertWarning('Campo Anticipo es requerido');
        return false
      }
      if (!this.data.forma_pago){
        this.sweetAlertWarning('Campo Forma de Pago es requerido');
        return false
      }
      return true;
    },
    async submitForm() {
      const validateData = await this.validateRequired();

      if (!validateData){
        return
      }
      this.overlay = true;
      const response = await this.createAnnexe(this.data);
      if (!response.error){
        this.sweetAlertSuccess('Se creo correctamente')
        await this.$router.push(`/licitaciones_documentos/${this.data.tenderId}`);
      }
      else{
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
    async submitFormFianza() {
      const validateData = await this.validateRequiredFianza();

      if (!validateData){
        return
      }
      this.overlay = true;
      const response = await this.createAnxPerformance(this.data);
      if (!response.error){
        this.sweetAlertSuccess('Se creo correctamente')
        await this.$router.push(`/licitaciones_documentos/${this.data.tenderId}`);
      }
      else{
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
    async submitFormSpec() {
      const validateData = await this.validateRequiredSpec();

      if (!validateData){
        return
      }
      this.overlay = true;
      const response = await this.createAnxSpec(this.data);
      if (!response.error){
        this.sweetAlertSuccess('Se creo correctamente')
        await this.$router.push(`/licitaciones_documentos/${this.data.tenderId}`);
      }
      else{
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
